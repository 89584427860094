/* –––GLOBAL––– */

@font-face {
  font-family: CodePro;
  src: url(./fonts/SourceCodePro.ttf);
}

:root {
  --accent-color: #ffd270;
  --background-color: rgb(255, 255, 255, 0.3);
  --alternate-background: var(--background-color) url("../public/images/img-noise-361x370_v2.png") repeat center;
  --text-color: #230007;
  --font-family: CodePro;
}

html, body {
  height: 100%;
  width: 100%;
  font-family: var(--font-family);
  color: var(--text-color);
  scroll-behavior: smooth;
}

.boxShadow {
  -webkit-box-shadow: 0px 4px 40px 0px rgba(35,0,7,0.05);
  -moz-box-shadow: 0px 4px 40px 0px rgba(35,0,7,0.05);
  box-shadow: 0px 4px 40px 0px rgba(35,0,7,0.05);
}


/* –––FONTS––– */

h1 {
  font-size: 32px;
  font-variation-settings: "wght" 650;
  letter-spacing: 0px;
  line-height: 40px;
  margin-bottom: 50px;
}

h2 {
  font-size: 18px;
  font-variation-settings: "wght" 400;
  letter-spacing: 2px;
  line-height: 23px;
}

h3 {
  font-size: 16px;
  font-variation-settings: "wght" 700;
  letter-spacing: 0px;
  line-height: 32px;
  text-transform: uppercase;
}

h3.name {
  line-height: 22px;  
}

p {
  font-size: 18px;
  font-variation-settings: "wght" 400;
  letter-spacing: 1px;
  line-height: 26px;
}

u {
    font-variation-settings: "wght" 350;
    text-decoration: underline;
}

/* –––FUNDAMENTALS––– */

.App{
  display: flex;
  justify-content: center;
}

.super_Container { 
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}

/* Styles für Tablets und kleiner */
@media only screen and (max-width: 1023px) {
  .super_Container {
    display: none;
    overflow: hidden;
  }

  .comingSoonWrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media only screen and (min-width: 1024px) {
  /* Styles für Desktops */
.comingSoonWrapper {
    display: none;
  }
}

.discoverOnDesktop {
  margin-top: 100px;
}

.noiseBackground {
  width: 100vw;
  height: 100vh;
  background: var(--alternate-background);
  z-index: 60;
  position: absolute;
}

.interface_Front {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 10;
  position: fixed;
  pointer-events: none;
}

.interface_Back {
  width: 100%;
  height: 100%;
  display: flex;
  /* background: var(--alternate-background); */
}


/* –––NAVI––– */

.navSection {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-color);
  backdrop-filter: blur(30px);
  padding: 12vh 12vh 12vh 10vh;
  pointer-events: all;
}

.navContainer {
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inactive {
  font-size: 2.1vw;
  font-variation-settings: "wght" 400;
  letter-spacing: 2px;
  line-height: 2.6vw;
  transition: 0.3s ease-in-out;
  margin: 15px 0 15px 0;
}

.inactive:hover {
  font-variation-settings: "wght" 800;
  transition: 0.3s ease-in-out;
  margin-left: 1vw;
}

.active {
  font-size: 2.1vw;
  font-variation-settings: "wght" 800;
  letter-spacing: 2px;
  line-height: 2.6vw;
  transition: 0.3s ease-in-out;
  margin: 15px 0 15px 1vw;;
  cursor: pointer;
}

.navList li {
  position: relative;
}

.hover-effect::before {
  content: "_";
  position: absolute;
  left: -1.5vw;
  /* Abstand zum Text anpassen */
  opacity: 0;
  transition: opacity 0.2s;
}

.active .hover-effect::before,
.navList li:hover .hover-effect::before {
  opacity: 1;
}

/* –––SIDEBAR––– */

.sideSection {
  width: 10%;
  height: 100%;
  background: var(--background-color);
  pointer-events: all;
  backdrop-filter: blur(30px);
}

.sideSection_Back {
  width: 10%;
  height: 100%;
}

.sideContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sideList li {
  margin: 35px 0 35px 0;
  width: 20px;
  transition: 0.3s ease-in-out;
}

.sideList li:hover {
  scale: 110%;
  transition: 0.3s ease-in-out;
}


/* –––CONTENT––– */

.contentSection {
  width: 60%;
  display: flex;
  justify-content: center;
  /* padding: 12vh 14vh 0 14vh; */
  padding: 12vh 7.7vw 0 7.7vw;
}

.contentContainer {
  position: relative;  
  max-width: 100%;
  min-width: 100%;
}


/* –––CONTENT > HOME––– */

.canvasFrame {
  width: 100%;
  margin-bottom: 12vh;
  background: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20%;
  aspect-ratio: 1 / 1;
}

.canvasContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.canvasFrame canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.canvasDiscription {
  width: 100%;
  height: 108%;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.discriptionParagraph {
  font-size: 12px;
}

.discriptionParagraph b{
  font-variation-settings: "wght" 700;
}

.p5iFrame {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.renderTESTblue {
  width: 100px;
  height: 100px;
  background-color: blue;
}

.renderTESTred {
  width: 100px;
  height: 100px;
  background-color: red;
}


/* –––CONTENT > ABOUT––– */

.spheresComp{
  height: 120px;
  margin: 30px 0 30px -10px;
}



/* –––CONTENT > PROJECTS––– */

.selectorWrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 44vw;
  z-index: 100;
  pointer-events: all;
}

.selectorContainer {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  height: unset;
  padding-bottom: 30px;
}

.selectedWorks {
  width: 216px;
  backdrop-filter: blur(30px);
  margin-left: -8px;
  padding: 0 5px 5px 5px;
  border-radius: 5px;
  background: var(--background-color);
}

.selectorContainer a {
  display: flex;
  margin: 6px 6px 0 0;
}

.inactiveBox {
  overflow: hidden;
  width: 40px;
  height: 10px;
  padding: 4px 10px 6px 10px;
  display: flex;
  justify-content: center;
  background-color: white;
  color: white;
  border-radius: 30px;
  border: 1px solid var(--text-color);
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  transition: width 0.2s ease-in 0.1s, height 0.2s ease-in 0.1s, color 0.1s ease-in;
}

.activeBox {
  overflow: hidden;
  border-radius: 30px;
  width: 40px;
  height: 10px;
  padding: 4px 10px 6px 10px;
  display: flex;
  justify-content: center;
  border: 1px solid var(--text-color);
  background-color: black;
  color: black;
  transition: width 0.2s ease-in 0.1s, height 0.2s ease-in 0.1s, color 0.1s ease-in;
}

.selectorContainer:hover .inactiveBox {
  width: 200px;
  height: 30px;
  color: black;
  transition: width 0.2s ease-out, height 0.2s ease-out, color 0.1s ease-out 0.2s, border-radius 0.5s ease-in;
}

.selectorContainer:hover .activeBox {
  width: 200px;
  height: 30px;
  color: white;
  transition: width 0.2s ease-out, height 0.2s ease-out, color 0.1s ease-out 0.2s;
}

.inactiveBox:hover {
  border-radius: 10px;
  transition: 0.2s ease-out !important;
}

.projectContainer {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.projects-img-container {
  width: 100%;
}

.projectsIMG {
  min-width: 100%;
}

/* ––– CONTENT - about / contact — Background Animation––– */

.aniContainer {
  position: relative;
}

.basicAni {
  position: fixed;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: -10;
}

.basicAni_B {
  position: fixed;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: -10;
  margin-left: -45vw;
  margin-top: -5vw;
}


/* –––PROJECTS > Eisfrau––– */

.projectHeader_Eisfrau {
  position: absolute;
  min-width: 110vh;
  align-self: center;
  margin: -62vh 0 0 0;
  z-index: -1;
}

.marginTopEis {
  margin-top: 50vh;
}

.eisfrauImg {
  min-width: 130%;
  margin: -50px 0 40px -100px;
}

.eisfrauSlide {
  min-width: 200%;
  margin: 60px 0 0 -320px;
}

/* –––PROJECTS > Paperback––– */
.marginTopAI {
  margin-top: 32vh;
}

.projectSplineContainer {
  position: absolute;
  align-self: center;
  width: 200%;
  height: 245%;
  margin-top: -120vh;
  z-index: -50;
}

.componentSpline {
  margin: 0px 0 0 -30px;
}

.AI-Anima {
  width: 140vh;
  margin-top: 75vh;
  margin-left: 7vw;
}

.paperbackIMG {
  width: 70vh;
  align-self: center;
  margin: 20px 0 20px 0;
  -webkit-box-shadow: 0px 4px 40px 0px rgba(35, 0, 7, 0.3);
  -moz-box-shadow: 0px 4px 40px 0px rgba(35, 0, 7, 0.3);
  box-shadow: 0px 4px 40px 0px rgba(35, 0, 7, 0.3);
}

.paperbackButton {
  width: 180px;
  display: flex;
  justify-content: center;
  padding: 4px;
  background: linear-gradient(30deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 0%, rgba(0, 212, 255, 1) 100%);
  border-radius: 22px;
  transition: 0.5s;
}

.paperbackButton:hover {
  -webkit-box-shadow: 0px 4px 40px 0px rgba(80, 194, 255, 0.5);
  -moz-box-shadow: 0px 4px 40px 0px rgba(80, 194, 255, 0.5);
  box-shadow: 0px 4px 40px 0px rgba(80, 194, 255, 0.5);
  transition: 0.5s;
}

.paperbackButtonBorder {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  border-radius: 18px;  
  border: 1px solid white;
  color: white;
  font-variation-settings: "wght" 650 !important;
  z-index: 10;
  }


/* –––PROJECTS > Cover Collection––– */
.noHeaderMarginCover {
  margin-top: 8vh;
}

.container-cover {
  display: flex;
  width: 100%;
}

.container-cover-margin-top {
  margin-top: -11vh;
}

.wrapper-cover {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.wrapper-cover-2 {
  display: flex;
  flex-direction: column;
  height: 71.2vh;
  justify-content: space-between;
}

.cover-collection-img {
  margin: 5px 5px 5px 5px;
  overflow: hidden;
  -webkit-box-shadow: 0px 4px 40px 0px rgba(35, 0, 7, 0.5);
  -moz-box-shadow: 0px 4px 40px 0px rgba(35, 0, 7, 0.5);
  box-shadow: 0px 4px 40px 0px rgba(35, 0, 7, 0.5);
  border-radius: 4px;
  transition: 0.6s;
}

.cover-collection-img:hover {
  margin-top: 12px;
  transition: 0.6s;
}

.self-end {
  align-self: flex-end;
}

.self-start {
  align-self: flex-start;
}

.cover-01 {
  min-width: 70vh;
  min-height: 70vh;
  max-width: 70vh;
  max-height: 70vh;  
  margin-left: -25vh;
}

.cover-02 {
  width: 30vh;
  height: 30vh;
}

.cover-03 {
  min-width: 30vh;
  min-height: 30vh;
  max-width: 30vh;
  max-height: 30vh;
  margin-left: -5vh;
  margin-bottom: 12vh;
}

.cover-04 {
  min-width: 50vh;
  min-height: 50vh;
  max-width: 50vh;
  max-height: 50vh;
}

.cover-05 {
  min-width: 30vh;
  height: 30vh;
}

.cover-06 {
  min-width: 50vh;
  min-height: 50vh;
  max-width: 50vh;
  max-height: 50vh;
  margin-left: -5vh;
}

.cover-07 {
  min-width: 70vh;
  min-height: 70vh;
  max-width: 70vh;
  max-height: 70vh;
  margin-left: 6.4vh;
}

.cover-08 {
  width: 30vh;
  height: 30vh;
  margin-left: 6.4vh;
}



/* –––PROJECTS > Motion Design––– */
.wrapperContainerMotion {
  display: flex;
  width: 100%;
}

.svgWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 55%;
  margin-right: 4vw;
}

.textMotionWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
  width: 45%;
}

.svg-MotionLogo {
  margin: 1vh 0 1vh 0;
  transition: 0.6s;
  height: 70px;
  max-width: 100%;
  scale: 0.95;
  height: 40%;
}

.gridContainerMotion {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust the width as per your requirement */
  grid-auto-rows: 10; /* Adjust the height as per your requirement */
  grid-gap: 10px; /* Adjust the gap between grid items */
}

.gridBarMotion {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-radius: 14px;
  -webkit-box-shadow: 0px 4px 40px 0px rgba(35,0,7,0.05);
  -moz-box-shadow: 0px 4px 40px 0px rgba(35,0,7,0.05);
  box-shadow: 0px 4px 40px 0px rgba(35,0,7,0.05);
  transition: 0.3s;

}

/* .gridBarMotion:hover ~ .gridBarMotion {
  -webkit-box-shadow: 0px 4px 40px 0px rgba(35,0,7,0.0);
  -moz-box-shadow: 0px 4px 40px 0px rgba(35,0,7,0.0);
  box-shadow: 0px 4px 40px 0px rgba(35,0,7,0.0);
  transition: 0.15s;
  scale: 0.97;
} */


@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  5% {
    transform: rotate(-1deg);
  }

  10% {
    transform: rotate(1deg);
  }

  15% {
    transform: rotate(-1deg);
  }

  20% {
    transform: rotate(1deg);
  }

  25% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }}

.barWiggle {
  animation: wiggle 3s infinite 3s;
}

.barWiggle:hover {
  animation: none;
}


.overlayContainer {
  width: 100vw;
  height: 100vh;
  padding: 20vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-color);
  backdrop-filter: blur(30px);
}

.videoContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  padding: 10px;
  /* border-radius: 18px;
  border: 1px solid white; */
}

.videoContainerColumn {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.aniELBA1 {
  max-height: 40vh;
  border-radius: 0 0 5px 5px;
}

.aniELBA2 {
  max-height: 40vh;
  border-radius: 0 0 5px 5px;
}

/* –––PROJECTS > WEB DEV ––– */

/* –––PROJECTS > SubsControl ––– */

.IMGoutroSubsControl{
  min-width: 140%;
  margin: 100px 0 -50% -20%;
}

/* –––PROJECTS > Abendkasse ––– */

.projectHeader_Abendkasse {
  position: absolute;
  min-width: 140%;
  align-self: center;
  margin: -62vh 0 0 0;
  z-index: -1;
  transform: rotate(180deg);
}

.imgAbendkasse {
  min-width: 120%;
  margin: -65% 0 0 -15%;
}

/* –––PROJECTS > VISCO ––– */

.VISCO-iFrame {
  border-radius: 5px;
}

/* –––CONTENT > CONTACT––– */

.contactContainer{
  height: 88vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contactOverlay{
  position: absolute;
  min-width: 90vw;
  min-height: 90vh;
  z-index: 10000;
  margin-left: -25vw;
  margin-top: -25vh;
  pointer-events: none;
}

.contactAvatar {
  width: 520px;
  margin-right: -80px;
  margin-top: -80px;
  align-self: flex-end;
}

@media (min-width: 1920px) {
  .contactAvatar {
    width: 760px;
    align-self: flex-end;
  }
}

.contactAvatar2 {
  min-width: 120%;
  margin: -20% 0 -5% -10%;
  align-self: flex-end;
}


/* –––PRIVACY / LEGAL––– */

.privacyContainer {
  margin-top: 5vw;
}


/* –––STUFF––– */
.cursorPointer {
  cursor: pointer;
}

.spaceHolder {
  height: 12vh;
}

.noHeaderMargin {
  margin-top: 22vh;
}

.justifyCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.positionAbsolute {
  position: absolute;
}

.borderRadius {
  border-radius: 5px;
}

.borderLine {
  border: 1px solid var(--text-color);
}


/* –– SVG –– */


/* –––FOOTER (in naContainer)––– */

.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footerFont {
  font-size: 12px;
  font-variation-settings: "wght" 400;
  letter-spacing: 3px;
  line-height: 20px;
  margin-top: 0;
  transition: 0.3s ease-in-out;
  text-decoration: underline;
}




